(function ($) {
 "use strict";

    /*----- main menu -----*/ 
    $(".navigation .dropdown").hover(            
        function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,true);
            $(this).toggleClass('open');        
        },
        function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,true);
            $(this).toggleClass('open');       
        }
    );
    
    $(".top-nav .dropdown").hover(            
        function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,true);
            $(this).toggleClass('open');        
        },
        function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,true);
            $(this).toggleClass('open');       
        }
    );


    function initSlider(){  
        $(".main-slider").not('.slick-initialized').slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            fade: true
        }); 
    }

    initSlider();

    $(document).ready(function() {

        $(window).scroll(function() {
            if ($(this).scrollTop() > 1){  
                $('.header_s').addClass("navbar-fixed-top animated fadeInDown");
            }
            else{
                $('.header_s').removeClass("navbar-fixed-top animated fadeInDown");
            }
        });
    });


    $(document).ready(function() {
        var $fc = $("#calendar");
        
        $("#calendar").fullCalendar({
            header: {
                left: "prev,next",
                center: "title",
                right: ""
            },
            defaultView: "month",
            eventClick:  function(event, jsEvent, view) {
                $('#modalTitle').html(event.title);
                $('#modalBody #startDate').html(moment(event.start).format('MMM Do h:mm A'));
                $('#modalBody #endDate').html(moment(event.end).format('MMM Do h:mm A'));
                $('#fullCalModal').modal();
            }
        });

        FCRemoteIcal.import($fc, [
          { url: "/calendar.ics", defaults: { color: "#fbc327" } },
        ]);
      
        $('#datepicker').datepicker({
            inline: true,
            onSelect: function(dateText, inst) {
                var d = new Date(dateText);
                $('#calendar').fullCalendar('gotoDate', d);
            }
        }); 
    });
         
    /*---------------------
     collapse 
    --------------------- */ 
    $('.panel_heading a').on('click', function(){
        $('.panel_heading a').removeClass('active');
        $(this).addClass('active');
    });
    /*---------------------
     tooltip 
    --------------------- */    
    $('[data-toggle="tooltip"]').tooltip();      

    /* default settings */
    $('.venobox_custom').venobox({
        framewidth: '700px',        // default: ''
        frameheight: '500px',       // default: ''              
    }); 

    // scroolup
    $.scrollUp({
        scrollText: '<i class="fa fa-angle-up"></i>',
        easingType: 'linear',
        scrollSpeed: 900,
        animation: 'fade'
    });
    
})(jQuery); 